import React from 'react'
import "./about.css"
import ButtonT from './button-t';
import Contact from '../Contact/Contact';

export default function About() {
  return (
    <div className='container'>
      <h1>About Us</h1>
      <p>
      Welcome to our website dedicated to currency exchange rates in Serbia! Our goal is to provide you with a convenient tool for accessing up-to-date information on currency exchange rates at various exchange offices throughout Serbia.
      </p>
      <h3>Contents:</h3>
      <p>
      <b>Interactive Map</b>: On our map, you can select the desired city and currency. Points on the map are colored from red to green depending on where the exchange rate is more favorable. The greener the point, the better the rate.
      </p>
      <p>
      <b>Rates Table</b>: In our table, you can also select the desired city and currency or choose all cities to display information for the whole of Serbia. The table shows the current currency exchange rates at various exchange offices.
      </p>
      <h4>Join Our Telegram Channel!</h4>
      <ButtonT onClick={() => {window.open('https://t.me/kursna_lista', '_blank').focus();}} ></ButtonT><br></br><br></br>
      <p>
      Want to stay informed about the latest currency exchange rate updates? Join our Telegram channel to receive notifications about the freshest exchange rate data directly to your telegram. Every day, our channel features a post with the best exchange rates for Belgrade and Novi Sad.
      </p>
      <p>
      <b>Disclaimer</b>: Please note that we do not take responsibility for the accuracy and reliability of the exchange rates provided on the website. The information on the website is provided solely for informational purposes, and we recommend verifying exchange rates at the exchange offices themselves before conducting currency exchange transactions.
      </p>
      <p>
      Thank you for visiting our website! If you have any questions or suggestions, please feel free to contact us. We are always happy to help.
      </p>
      <Contact/>
    </div>
  )
}
