import React from 'react'

export default function Error404() {
  return (
    <div className='error-container' style={{margin: "8px"}}>
        <h1>
            404
        </h1>
        <h3>
            Page not found
        </h3>
    </div>
  )
}
