import './button-t.css'

export default function ButtonT({onClick}) {
    return (
        <div className='about-button-margin'>
            <button className="about-button" onClick={onClick}>
                <span><b>Join</b></span>
                <img src={"/telegram.png"} alt={'Telegram'}/>
            </button>
        </div>
    )
}