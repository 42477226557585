import React from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';

export default function Navbar(){
  return (
    // <div className='heading'>
      <div className="heading"> 
        <div className='left-heading'>
          <Link to="/"><img src="/logo_onestroke_cut.png" alt="logo" ></img></Link>
        </div>
        <div className='right-heading'>
          <Link to="/"><span>Map</span></Link>
          <Link to="/table"><span>Table</span></Link>
          <Link to="/about"><span>About</span></Link>
          {/* <Link to="/grafs"><span>Grafs</span></Link> */}
        </div>
      {/* </div> */}
      {/* <div className='right-heading'>
      </div> */}
    </div>
  );
}