import React, {useEffect, useState } from "react";
import Selector from "../map/selector";
import SelectorType from "../map/selector-type";
import SelectorCity from "../map/selector-city";
import axios from "axios";
import Spinner from "./spinner";
import "./table.css"



export default function Table(){
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');
    const [selectedCity, setSelectedCity] = useState('all');
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bestBuyRate, setBestBuyRate] = useState(0);
    const [bestSellRate, setBestSellRate] = useState(0);

    const sortByName = (x, y) => {
      return x.name.localeCompare(y.name, "en");
    };

    const sortByBuy = (x, y) => {
      let a = y.rates[selectedCurrency]? y.rates[selectedCurrency].buy: 0 
      let b = x.rates[selectedCurrency]? x.rates[selectedCurrency].buy: 0 
      return a - b;
    };

    const sortBySell = (x, y) => {
      let a = y.rates[selectedCurrency]? y.rates[selectedCurrency].sell: 100000
      let b = x.rates[selectedCurrency]? x.rates[selectedCurrency].sell: 100000
      return b - a;
    };

    useEffect(() => {
    setLoading(true);
    get_table_data(selectedCity).then((data) => {
      let c_data = data.data;
      setRows(c_data);
      let max_current = 0;
      let min_current = 100000000;
      for (let i = 0; i < c_data.length; i += 1) {
        if (c_data[i].rates[selectedCurrency]) {
          max_current = Math.max(max_current, c_data[i].rates[selectedCurrency].buy);
          min_current = Math.min(min_current, c_data[i].rates[selectedCurrency].sell);
        };
      };
      setBestBuyRate(max_current)
      setBestSellRate(min_current)
      setLoading(false);
      });
    },[selectedCurrency, selectedCity])

    return (
      <div className="table-container">
            <h1 className="h1-table">Exchange rates in Serbia</h1>
            <div className="selector-container">
              <Selector onSelect={(e) => {setSelectedCurrency(e.target.value); console.log(e.target.value);}}></Selector>
              <SelectorCity onSelect={(e) => {setSelectedCity(e.target.value); console.log(e.target.value)}}></SelectorCity>
            </div>
            <table id="tb" className="table-rates">
                <tr className="head-table-rates">
                    <th className="head_table_row" onClick={() => setRows([...rows.sort(sortByName)])}>Exchangers</th>
                    {/* <th></th> */}
                    <th className="head_table_row" onClick={() => setRows([...rows.sort(sortByBuy)])}>Buy</th>
                    <th className="head_table_row" onClick={() => setRows([...rows.sort(sortBySell)])}>Sell</th>
                </tr>
                {rows?.map((row) => (
                  <tr className="table-row">
                    <td className="table-link"><a href={row.url}>{row.name}</a></td>
                    {/* <td className="best-mark">{(row.rates[selectedCurrency]?.buy === bestBuyRate || row.rates[selectedCurrency]?.sell === bestSellRate)? 'Best' : ''}</td> */}
                    <td className={row.rates[selectedCurrency]?.buy === bestBuyRate ? 'best-rate' : ''}>{row.rates[selectedCurrency]? parseFloat(row.rates[selectedCurrency].buy.toFixed(4)) : null}</td>
                    <td className={row.rates[selectedCurrency]?.sell === bestSellRate ? 'best-rate' : ''}>{row.rates[selectedCurrency]? parseFloat(row.rates[selectedCurrency].sell.toFixed(4)) : null}</td>
                  </tr>
                ))}
            </table>

            {loading ? (
              <Spinner/>
            ): null}
      </div>
    );
  }



async function get_table_data(city = "all") {
if (city.toLowerCase() == "all") {
    try {
    const response = await axios.get('/api/table/data');
    return response.data;
    } catch (error) {
    console.error(error);
    }
}
else {
    try { 
    const response = await axios.get('/api/table/data/city/' + city.toLowerCase());
    return response.data;
    } catch (error) {
    console.error(error);
    }
}
}