import './selector.css'


export default function SelectorCity({onSelect}) {
    

    return (
        <div className='selector-margin'>
            <div className='selector-wrapper'>
                <select className="selector" defaultValue="all"  onChange={onSelect} >
                    It's Selector
                    <option value={"all"}>All cities</option>
                    <option value={"Beograd"}>Beograd</option>
                    <option value={"Novi_Sad"}>Novi Sad</option>
                    <option value={"Niš"}>Niš</option>
                    <option value={"Kragujevac"}>Kragujevac</option>
                    <option value={"Subotica"}>Subotica</option>
                    <option value={"Lazarevac"}>Lazarevac</option>
                    <option value={"Pančevo"}>Pančevo</option>
                    <option value={"Čačak"}>Čačak</option>
                    <option value={"Kraljevo"}>Kraljevo</option>
                    <option value={"Sremska_Mitrovica"}>Sremska Mitrovica</option>
                </select>
            </div>
        </div>
    )
}