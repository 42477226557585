import './selector.css'


export default function SelectorType({onSelect}) {
    

    return (
        <div className='selector-margin'>
            <div className='selector-wrapper'>
                <select className="selector" defaultValue="buy"  onChange={onSelect} >
                    It's Selector
                    <option value={"buy"}>buy</option>
                    <option value={"sell"}>sell</option>
                </select>
            </div>
        </div>
    )
}