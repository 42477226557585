import './selector.css'


export default function Selector({onSelect}) {
    

    return (
        <div className='selector-margin'>
            <div className='selector-wrapper'>
                <select className="selector" defaultValue="EUR"  onChange={onSelect} >
                    It's Selector
                    <option value={"EUR"}>EUR</option>
                    <option value={"USD"}>USD</option>
                    <option value={"CHF"}>CHF</option>
                    <option value={"AUD"}>AUD</option>
                    <option value={"CAD"}>CAD</option>
                    <option value={"GBP"}>GBP</option>
                    <option value={"HUF"}>HUF</option>
                    <option value={"BAM"}>BAM</option>
                    <option value={"RUB"}>RUB</option>
                </select>
            </div>
        </div>
    )
}