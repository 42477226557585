import './App.css';
import Map from './components/map/map.jsx';
import Table from './components/table/table';
import Navbar from './components/navbar/navbar.jsx';
import Error404 from './components/error/404.jsx';
import About from './components/About/about.jsx';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
          <Route path="/table" element={<Table/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/" element={<Map/>}/>
          <Route path='*' element={<Error404/>}/>
      </Routes>
    </div>
  );
}

export default App;
