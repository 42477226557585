import React, { useRef, useEffect, useState } from 'react';
import * as maptilersdk from '@maptiler/sdk';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './map.css';
import axios from "axios";

import Button from './button.jsx';
import Selector from './selector.jsx';
import SelectorType from './selector-type.jsx';
import SelectorCity from './selector-city.jsx';
import Spinner from '../table/spinner.jsx';



export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const cities = {
    "Novi_Sad": [19.84621, 45.24808],
    "Beograd": [20.459101, 44.810706],
    "Subotica": [19.65840, 46.09645],
    "Niš": [21.90474, 43.31724],
    "Kragujevac": [20.91296, 44.01175],
    "Lazarevac": [20.25706, 44.38324],
    "Pančevo": [20.64697, 44.87273],
    "Čačak": [20.34932, 43.88904],
    "Kraljevo": [20.68912, 43.72528],
    "Sremska_Mitrovica": [19.60956, 44.97202],
  }
  const [zoom] = useState(12);
  const api_key =  '8W1NYqbgJ7P6U3ztltw9'
  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const [selectedTypeOperation, setSelectedTypeOperation] = useState('buy');
  const [selectedCity, setSelectedCity] = useState('all');
  const [loading, setLoading] = useState(false);


  maptilersdk.config.apiKey = api_key;
  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: "f2e4f742-4ea9-49bf-8fac-8d5be52acf16",
      center: cities["Beograd"],
      zoom: zoom,
      geolocateControl: true
    });

    map.current.on('load', async function() {
        map.current.loadImage("/point.png", function (error, image) {
            if (error) throw error;
            map.current.addImage('Point_icon', image, { sdf: true });
            reload_layer(selectedCurrency + "_" + selectedTypeOperation, selectedCity);
            map.current.on('click', 'Exchangers', function (e) {
              var coordinates = e.features[0].geometry.coordinates.slice();
              var description = 
              "Name: " + e.features[0].properties.name
              if (e.features[0].properties.info) {
                description = description + ', ' + e.features[0].properties.info;
              };
              description = description + '<br />' +
              "Address: " + e.features[0].properties.address + "<br />" +
              "EUR buy: " + e.features[0].properties.EUR_buy + "<br />" +
              "EUR sell: " + e.features[0].properties.EUR_sell + "";
              // if (e.features[0].properties[selectedCurrency + "_buy"]) {
              //   description = description +
              //   "<p>" + selectedCurrency + " buy: " + e.features[0].properties[selectedCurrency + "_buy"] + '</p>' +
              //   "<p>" + selectedCurrency + " sell: " + e.features[0].properties[selectedCurrency + "_sell"] + "</p>";
              // };

  
              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
  
              new maptilersdk.Popup({closeButton: false, closeOnClick: true})
                  .setLngLat(coordinates)
                  .setHTML(description)
                  .addTo(map.current);
            });
          // Change the cursor to a pointer when the mouse is over the places layer.
            map.current.on('mouseenter', 'Exchangers', function () {
              map.current.getCanvas().style.cursor = 'pointer';
            });
          // Change it back to a pointer when it leaves.
            map.current.on('mouseleave', 'Exchangers', function () {
              map.current.getCanvas().style.cursor = '';
            });
          });
        });
  }, []);



  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map">
        <div className='left-top-margin'>
          <SelectorCity onSelect={(e) => {setSelectedCity(e.target.value); console.log(e.target.value); fly_to(e.target.value); reload_layer(selectedCurrency + "_" + selectedTypeOperation, e.target.value);}}></SelectorCity>
          <Selector onSelect={(e) => {setSelectedCurrency(e.target.value); console.log(e.target.value); reload_layer(e.target.value + "_" + selectedTypeOperation, selectedCity);}}></Selector>
          <SelectorType onSelect={(e) => {setSelectedTypeOperation(e.target.value); console.log(e.target.value); reload_layer(selectedCurrency + "_" + e.target.value, selectedCity)}}></SelectorType>
          <Button onClick={() => {window.open('https://t.me/kursna_lista', '_blank').focus();}} ></Button> 
        </div>
      </div>
      {loading ? (
      <Spinner/>
      ): null}
    </div>
  );

  
  function reload_layer(currency_and_type , city='all') {
    // If a layer with ID 'state-data' exists, remove it.
    
    if (map.current.getLayer('Exchangers')) map.current.removeLayer('Exchangers');
    if (map.current.getSource("Exchangers_points")) map.current.removeSource('Exchangers_points');

    let max_current = 0;
    let min_current = 0;
    let close_max = 0;

    let typeOperation = currency_and_type.split("_")[1]
    setLoading(true);
    get_map_data(city).then((geojson) => {
        
        for (let i = 0; i < geojson.features.length; i += 1) {
          if (geojson.features[i].geometry.coordinates == [0, 0]) {
            delete geojson.features[i];
          };
          if (!geojson.features[i].properties[currency_and_type]) {
            delete geojson.features[i];
          };
        };
        geojson.features = geojson.features.filter((n) => { return !!n })
        map.current.addSource('Exchangers_points', {
          type: 'geojson',
          data: geojson,
        });
        return geojson
        })
        .then((geojson) => {
        for (let i = 0; i < geojson.features.length; i += 1) {
          max_current = Math.max(max_current, geojson.features[i].properties[currency_and_type]);
        };
        min_current = max_current;
        for (let i = 0; i < geojson.features.length; i += 1) {
          min_current = Math.min(min_current, geojson.features[i].properties[currency_and_type]);
        };

        if (typeOperation == "sell") {
          [min_current, max_current] = [max_current, min_current]; 
          close_max = max_current + (min_current- max_current) * 0.1;
        } else {
          close_max = min_current + (max_current - min_current) * 0.9;
        }
    
      
        if (max_current == min_current) {
          map.current.addLayer({
            id: 'Exchangers',
            type: 'symbol',
            source: 'Exchangers_points',
            minzoom: 0,
            layout: {
                'icon-image': 'Point_icon',
                'icon-size': 0.12,
                'icon-anchor': 'center',
                "icon-allow-overlap": true,
                'text-allow-overlap': true,
                'text-optional': true,
                'text-padding': 0,
                'text-field': ['get', currency_and_type],
                'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                    ],
                // 'text-offset': [0, -3.4],
                'text-anchor': 'center',
                'text-size' : 12,
            },
            paint: {
                  'icon-color': "rgba(41, 187, 106, 1)",
                  'text-color': "rgba(255, 255, 255, 1)"
              },
          });
          return;
        };


        if (typeOperation == "buy") {
          map.current.addLayer({
              id: 'Exchangers',
              type: 'symbol',
              source: 'Exchangers_points',
              minzoom: 0,
              layout: {
                  'icon-image': 'Point_icon',
                  'icon-size': [
                    'interpolate',
                    ['linear'],
                    ['get', currency_and_type],
                    min_current, 0.09,
                    close_max, 0.11,
                    max_current, 0.12,
                  ],
                  'icon-anchor': 'center',
                  "icon-allow-overlap": true,
                  'text-optional': true,
                  'text-padding': 0,
                  'text-field': ['get', currency_and_type],
                  'text-font': [
                      'Open Sans Semibold',
                      'Arial Unicode MS Bold'
                      ],
                  // 'text-offset': [
                  //   'interpolate',
                  //   ['linear'],
                  //   ['get', currency_and_type],
                  //   min_current, ["literal", [0, -2.26]],
                  //   close_max, ["literal", [0, -2.94]],
                  //   max_current, ["literal", [0, -3.4]],
                  // ],
                  'text-anchor': 'center',
                  'text-size' : 12,
              },
              paint: {
                    'icon-color': [
                      'interpolate',
                      ['linear'],
                      ['get', currency_and_type],
                      min_current, "rgba(226, 128, 144, 1)",
                      close_max, "rgba(245, 208, 51, 1)",
                      max_current, "rgba(41, 187, 106, 1)",
                    ],
                    'text-color': "rgba(255, 255, 255, 1)"
                },
          });
        } else {
          map.current.addLayer({
            id: 'Exchangers',
            type: 'symbol',
            source: 'Exchangers_points',
            minzoom: 0,
            layout: {
                'icon-image': 'Point_icon',
                'icon-size':  [
                  'interpolate',
                  ['linear'],
                  ['get', currency_and_type],
                  max_current, 0.12,
                  close_max, 0.11,
                  min_current, 0.09,
                ],
                'icon-anchor': 'center',
                "icon-allow-overlap": true,
                'text-optional': true,
                'text-padding': 0,
                'text-field': ['get', currency_and_type],
                'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                    ],
                // 'text-offset': [
                //   'interpolate',
                //   ['linear'],
                //   ['get', currency_and_type],
                //   max_current, ["literal", [0, -3.4]],
                //   close_max, ["literal", [0, -2.94]],
                //   min_current, ["literal", [0, -2.26]],
                // ],
                'text-anchor': 'center',
                'text-size' : 12,
            },
            paint: {
                  'icon-color': [
                    'interpolate',
                    ['linear'],
                    ['get', currency_and_type],
                    max_current, "rgba(41, 187, 106, 1)",
                    close_max, "rgba(245, 208, 51, 1)",
                    min_current, "rgba(226, 128, 144, 1)",
                  ],
                  'text-color': "rgba(255, 255, 255, 1)"
              },
        });
        }
      }).then(()=> {setLoading(false)});
  }

  function fly_to(city_name) {
    let city_coord = cities[city_name]

    map.current.flyTo({
      center: city_coord 
    })
  }
}

async function get_map_data(city = "all") {
  if (city.toLowerCase() == "all") {
    try {
      const response = await axios.get('/api/map/data'); // for deploy '/api/map/data'
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  else {
    try {
      const response = await axios.get('/api/map/data/city/' + city.toLowerCase());
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}


