import './button.css'

export default function Button({onClick}) {
    return (
        <div className='button-margin'>
            <button className="button" onClick={onClick}>
                <img src={"/telegram.png"} alt={'Telegram'}/>
            </button>
        </div>
    )
}