import React, {useEffect, useState} from 'react'
import "./Contact.css"

export default function Contact() {
    const [name, setName] = useState("")
    const [text, setText] = useState("")
    const [email, setEmail] = useState("")
    const [nameError, setNameError] = useState("Name field is empty")
    const [textError, setTextError] = useState("Text field is empty")
    const [emailError, setEmailError] = useState("Text field is empty")
    const [isValid, setIsValid] = useState(false)


    const nameHandler = (e) => {
        setName(e.target.value)
        if (e.target.value == "") {
            setNameError("Name field is empty")
        }
        else {
            setNameError("")
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        if (e.target.value == "") {
            setEmailError("Email field is empty")
        }
        else {
            if (e.target.value.match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                    setEmailError("")
                    
                } else {
                    setEmailError("Email field isn't valid")
                }
        }
    }

    const textHandler = (e) => {
        setText(e.target.value)
        if (e.target.value == "") {
            setTextError("Name field is empty")
        }
        else {
            setTextError("")
        }
    }

    useEffect(() => {
        if (nameError||textError||emailError) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [nameError, textError, emailError])

    const sendMessage = (e) => {
        e.preventDefault()
        if (isValid) {
            fetch('/api/send/message', {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({name, email, text})
            })
            setName("");
            setEmail("");
            setText("");
        }
    }

  return (
    <div>
        <form>
            <h3>Contact Us</h3>
            <input value={name} onChange={e => nameHandler(e)} className="inputName" name="Name" type="text" placeholder='Enter your name'></input>
            <input value={email} onChange={e => emailHandler(e)} className="inputName" name="Email" type="text" placeholder='Enter your email'></input>
            <input value={text} onChange={e => textHandler(e)} className="inputText" name="Text" type="text" placeholder='Enter your message'></input>
            <div className='send-button-margin'><button className="sendButton" disabled={!isValid} onClick={e => sendMessage(e)}>Send</button></div>
            
        </form>
    </div>
  )
}
